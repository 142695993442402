import { Component, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { UserUtils } from './user/user-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject();
  title = 'Fusion';
  loading = true;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private loadingSvc: LoadingService,
  ) {
    this.setupEvents();
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        this.cd.detectChanges();
      }
      else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        if (!this.loadingSvc.loadingState) {
          this.loading = false;
          this.cd.detectChanges();
        }
      }
    });

    // disable default undo/redo behavior
    document.body.onkeydown = function (e) {
      if (e.ctrlKey || e.metaKey) {
        if (e.code == 'KeyZ') {
          e.preventDefault();
          return false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setupEvents() {
    this.loadingSvc.loadingStateChange.pipe(takeUntil(this.unsubscribe)).subscribe(l =>
      this.loading = l
    );

    window.addEventListener('storage', (event) => {
      const token: string = sessionStorage.getItem('USER_TOKEN');

      // on request, process to share token
      if (event.key === 'REQUESTING_TOKEN' && token) {
        let user = JSON.parse(localStorage.getItem('currentUser'));

        if (user && user.token) {
          localStorage.setItem('SHARING_TOKEN', JSON.stringify(user.token));
          localStorage.removeItem('SHARING_TOKEN');
        }
      }

      // set token
      if (event.key === 'SHARING_TOKEN' && !token)
        sessionStorage.setItem('USER_TOKEN', event.newValue);

      // remove token
      if (event.key == 'REMOVE_TOKEN' && token)
        sessionStorage.removeItem('USER_TOKEN');
    });

    // request for token
    if (!UserUtils.getRememberMe()) {
      localStorage.setItem('REQUESTING_TOKEN', Date.now().toString());
      localStorage.removeItem('REQUESTING_TOKEN');
    }
  }
}
