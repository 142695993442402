import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'sudo',
  template: ''
})
export class SudoComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    let token: string = this.route.snapshot.paramMap.get('token');

    this.authService.sudo(token).subscribe(_ => {
      this.router.navigateByUrl('/');
    }, error => {
      this.router.navigate(['login']);
    });
  }
}
