import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the auth header from the service.
    const authHeader = this.getAuthorizationHeader();
    // Clone the request to add the new header.
    const authReq = req.clone({ setHeaders: { Authorization: authHeader } });
    // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }

  getAuthorizationHeader(): string {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser && currentUser.token)
      return 'Bearer ' + currentUser.token;
    else
      return '';
  }
}