import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorService } from "./error/error-service";

@Injectable()
export class AppErrorHandler implements ErrorHandler {

    constructor(private errorSvc: ErrorService) { }

    handleError(error: any): void {
        console.log("Some error ocurred.");
        console.log(error);
        this.errorSvc.log(error);
        // redirect to screen (like 'aw snap' in chrome)
        // window.location.href = '/error';
    }
}