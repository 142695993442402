import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

const fieldParamName = 'fields';
const pageSizeParamName = 'per-page';
const pageParamName = 'page';
const sortParamName = 'sort';

@Injectable()
export class DataStore<T extends Identifiable> {

    endPoint: string;

    constructor(protected http: HttpClient) { }

    get(id: string | any): Observable<T> {
        return this.http.get<T>(this.endPoint + '/' + id);
    }

    find(search: T | any, sort: string = null, fields: string[] = null): Observable<T[]> {
        let params = this.generateParams({ 'filter': search });

        // set no page limit (0)
        params = params.append(pageSizeParamName, '0');

        if (sort) params = params.append(sortParamName, sort);
        if (fields) params = params.append(fieldParamName, fields.join(','));

        return this.http.get<T[]>(this.endPoint, { params: params });
    }

    findAll(sort: string = null, fields: string[] = null): Observable<T[]> {
        return this.find({}, sort, fields);
    }

    findPaginated(search: T | any, pageNum: number = 0, pageSize: number = null, sort: string = null, fields: string[] = null): Observable<PaginatedData<T>> {
        let params = this.generateParams({ 'filter': search });

        params = params.append(pageParamName, pageNum.toString());

        if (pageSize) params = params.append(pageSizeParamName, pageSize.toString());
        if (sort) params = params.append(sortParamName, sort);
        if (fields) params = params.append(fieldParamName, fields.join(','));

        return this.http.get<T[]>(this.endPoint, { params: params, observe: 'response' }).pipe(
            map<HttpResponse<T[]>, { pagination: Pagination, data: T[] }>((resp) => {
                return {
                    pagination: this.parsePagination(resp.headers),
                    data: resp.body
                };
            })
        );
    }

    findAllPaginated(page: number = 0, pageSize: number = null, sort: string = null, fields: string[] = null): Observable<PaginatedData<T>> {
        return this.findPaginated({}, page, pageSize, sort, fields);
    }

    insert(instance: T): Observable<T> {
        return this.http.post<T>(this.endPoint, instance);
    }

    update(instance: T): Observable<T> {
        return this.http.put<T>(this.endPoint + "/" + this.getIdString(instance), instance);
    }

    delete(instance: T | Identifiable): Observable<any> {
        return this.http.delete(this.endPoint + "/" + this.getIdString(instance));
    }

    deleteBy(criteria: T | any): Observable<any> {
        let params = this.generateParams({ 'filter': criteria });
        return this.http.delete(this.endPoint, { params: params });
    }

    protected getIdString(instance: T | Identifiable): string {
        let idString = [];
        let id = instance.id;
        if (id !== Object(id)) {
            idString.push(id);
        } else {
            for (let key in id) {
                idString.push(id[key]);
            }
        }
        return idString.join(",");
    }

    protected generateParams(object: any): HttpParams {
        let paramObjs = [];
        this.flatObject(object, paramObjs);
        let params = new HttpParams();
        for (let paramDef of paramObjs)
            params = params.append(paramDef.key, paramDef.value);
        // for (var key in object) {
        //     if (object.hasOwnProperty(key)) {
        //         let val = object[key];
        //         params = params.append(key, val);
        //     }
        // }
        return params;
    }

    protected flatObject(obj: any, accumulator: { key: string, value: string }[], parent: string = null) {
        if (obj instanceof Array) {
            for (let elem of obj) {
                this.flatObject(elem, accumulator, parent + "[]");
            }
        } else if (obj !== Object(obj)) {
            accumulator.push({ key: parent, value: obj });
        } else {
            for (var key in obj) {
                if (!obj.hasOwnProperty || obj.hasOwnProperty(key)) {
                    let val = obj[key];
                    this.flatObject(val, accumulator, parent ? parent + "[" + key + "]" : key);
                }
            }
        }
    }

    protected parsePagination(headers: HttpHeaders): Pagination {
        return {
            totalCount: Number.parseInt(headers.get('X-Pagination-Total-Count')),
            pageCount: Number.parseInt(headers.get('X-Pagination-Page-Count')),
            currentPage: Number.parseInt(headers.get('X-Pagination-Current-Page')),
            perPage: Number.parseInt(headers.get('X-Pagination-Per-Page'))
        }
    }
}

export interface Identifiable {

    /**
     * Defines the identify of the entity. If it is a string
     * the field name is assumed to be "id", if an object the
     * keys are used as the field(s) name(s)
     */
    id: any | string;
}

export interface Pagination {
    totalCount: number;
    pageCount: number;
    currentPage: number;
    perPage: number;
}

export interface PaginatedData<T> {
    pagination: Pagination;
    data: T[];
}