import { Injectable } from "@angular/core";
import { HttpClient, } from "@angular/common/http";
import { Observable } from "rxjs";
import { RecaptchaResponse } from "./recaptcha-response";

@Injectable()
export class RecaptchaService {

    constructor(
        private http: HttpClient
    ) { }

    verify(response: string): Observable<RecaptchaResponse> {
        return this.http.post<RecaptchaResponse>('api/verify-recaptcha', { response: response });
    }
}