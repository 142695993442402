import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Country } from '../../data/country';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-profileFormGroup',
    templateUrl: './profile-form-group.component.html',
    styleUrls: ['./profile-form-group.component.css']
})
export class ProfileFormGroupComponent {

    countries: Country[] = [];
    profileFormGroup: FormGroup;

    errors = {
        firstName: 'Please specify your first name.',
        lastName: 'Please specify your last name.',
        emailFormat: 'Please specify your email address in correct format.',
        country: 'Please specify your country.',
        institution: 'Please specify your institution / company.',
        position: 'Please specify your position.'
    };

    @Input() showComment: boolean = true;

    constructor(
        private fb: FormBuilder,
        private http: HttpClient
    ) {
        this.http.get<Country[]>('assets/countries.json').subscribe((list) => this.countries = list);
        this.createForm();
    }

    get first_name() { return this.profileFormGroup.get('first_name'); }
    get middle_name() { return this.profileFormGroup.get('middle_name'); }
    get last_name() { return this.profileFormGroup.get('last_name'); }
    get email2() { return this.profileFormGroup.get('email2'); }
    get country_code() { return this.profileFormGroup.get('country_code'); }
    get institution() { return this.profileFormGroup.get('institution'); }
    get position() { return this.profileFormGroup.get('position'); }
    get website() { return this.profileFormGroup.get('website'); }
    get comment() { return this.profileFormGroup.get('comment'); }

    get valid(): boolean {
        return this.profileFormGroup && this.profileFormGroup.valid;
    }

    get value(): any {
        return this.profileFormGroup ? this.profileFormGroup.value : Object.create(null);
    }

    private createForm() {
        this.profileFormGroup = this.fb.group({
            id: '',
            first_name: ['', Validators.required],
            middle_name: '',
            last_name: ['', Validators.required],
            email2: ['', emailOrEmpty],
            country_code: ['', Validators.required],
            institution: ['', Validators.required],
            position: ['', Validators.required],
            website: '',
            comment: ''
        });
    }
}

function emailOrEmpty(control: AbstractControl): ValidationErrors | null {
    return control.value === '' ? null : Validators.email(control);
}