import { Component } from '@angular/core';

@Component({
  selector: 'app-preloader',
  templateUrl: 'preloader.component.html',
  styleUrls: ['preloader.component.css']
})
export class PreloaderComponent {

}
