import * as _ from "lodash";

export class ObjectUtils {

    static diff(a, b): any {
        function changes(object, base) {
            return _.transform(object, function (result, value, key) {
                if (!_.isEqual(value, base[key]))
                    result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
            });
        }
        return changes(a, b);
    }

    static equals(a, b): boolean {
        return _.isEqual(a, b);
    }

    static merge(a, b) {
        _.merge(a, b);
    }

    static pick(a, b) {
        return _.pick(a, _.keys(b));
    }

    static omit(a, b) {
        return _.omit(a, _.keys(b));
    }

    static clone(a, deep: boolean = true): any {
        return deep ? _.cloneDeep(a) : _.clone(a);
    }

    static get(a, path) {
        return _.get(a, path);
    }

    static set(a, path, value) {
        _.set(a, path, value);
    }

    static keys(a) {
        return _.keys(a);
    }

    static getKeys(a) {
        const keyify = (obj, prefix = '') =>
            Object.keys(obj).reduce((res, el) => {
                if (Array.isArray(obj[el])) {
                    return res;
                } else if (typeof obj[el] === 'object' && obj[el] !== null) {
                    return [...res, ...keyify(obj[el], prefix + el + '.')];
                } else {
                    return [...res, prefix + el];
                }
            }, []);

        return keyify(a);
    }

    /**
     * 
     * @param array 
     * @param symmetric if true, symmetric pairs will be outputted (e.g., X,Y and Y,X). defaults to false.
     */
    static pairs(array: any[], symmetric: boolean = false): any[][] {
        let pairs: any[][] = [];

        for (let e1 of array) {
            for (let e2 of array) {
                if (symmetric) {
                    if (
                        !(_.isEqual(e1, e2))
                        && (pairs.filter(pair => _.isEqual(pair[0], e1) && _.isEqual(pair[1], e2)).length == 0)
                    )
                        pairs.push([e1, e2]);
                } else {
                    if (
                        !(_.isEqual(e1, e2))
                        && (pairs.filter(pair => _.isEqual(pair[0], e1) && _.isEqual(pair[1], e2)).length == 0)
                        && (pairs.filter(pair => _.isEqual(pair[0], e2) && _.isEqual(pair[1], e1)).length == 0)
                    )
                        pairs.push([e1, e2]);
                }
            }
        }

        return pairs;
    }

    // https://github.com/SeregPie/lodash.combinations#readme
    /**
     * 
     * @param values 
     * @param n the length of each resulting combination
     */
    static combinations(values, n: number) {
        let rr = function (func) {
            let recur = function (...args) {
                return func.call(this, recur, ...args);
            };
            return recur;
        }

        values = _.values(values);
        let combinations = [];

        rr((recur, combination, index) => {
            if (combination.length < n) {
                _.find(values, (value, index) => {
                    recur(_.concat(combination, [value]), index + 1);
                }, index);
            } else {
                combinations.push(combination);
            }
        })([], 0);

        return combinations;
    }

    // https://codereview.stackexchange.com/questions/7001/generating-all-combinations-of-an-array
    static combinationsOfArrays(values: any[][]): any[][] {
        if (values.length == 0)
            return [];
        else if (values.length == 1)
            return values[0];

        let result = [];

        let allCasesOfRest: any[] = this.combinationsOfArrays(values.slice(1));  // recur with the rest of array

        for (let c in allCasesOfRest) {
            for (var i = 0; i < values[0].length; i++) {
                let a: any = values[0][i];
                let b: any[] = allCasesOfRest[c];
                result.push([a].concat(b));
            }
        }

        return result;
    }

    /**
     * If the given parameter is not an array it will be wrapped inside one. If
     * the parameter is an array the same will be returned
     * @param elements A single object or array
     * @returns The same array if an array is given or a new array containing the
     * single element if one is given
     */
    static makeArray<T>(elements: T[] | T): T[] {
        if (!(elements instanceof Array))
            elements = [elements];
        return elements;
    }
}