import { Injectable } from '@angular/core';
import { PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
//import 'rxjs/add/observable/of';
// Cool library to deal with errors: https://www.stacktracejs.com
import * as StackTraceParser from 'error-stack-parser';
import { ErrorLog } from './error-log';
import { ErrorLogStore } from './error-log.store';

@Injectable()
export class ErrorService {

    constructor(
        private errorLogStore: ErrorLogStore
    ) { }

    log(error: any) {
        if (!error || typeof error == 'string') return;
        if (error['ngDebugContext']) return;
        const errorToSend: ErrorLog = this.addContextInfo(error);
        this.errorLogStore.insert(errorToSend).subscribe(_ => { console.log('Error log saved.'); });
    }

    protected addContextInfo(error: any): ErrorLog {
        // All the context details that you want (usually coming from other services; Constants, UserService...)
        // const name = error['name'] || null;
        // const appId = 'shthppnsApp';
        // const user = 'ShthppnsUser';
        // const time = new Date().getTime();
        // const id = `${appId}-${user}-${time}`;
        // const location = this.injector.get(LocationStrategy);
        // const url = location instanceof PathLocationStrategy ? location.path() : '';
        // const status = error.status || null;
        const message = error['message'] || error.toString();
        const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
        const errorToSend: ErrorLog = { id: "", stack: stack, message: message };
        return errorToSend;
    }
}