import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from "./log/logger.service";
import { USER_LOGIN, USER_LOGOUT } from "./log/user-activity-map";
import { UserUtils } from './user/user-utils';

@Injectable()
export class AppGuard implements CanActivate {

    constructor(
        private router: Router,
        private logger: LoggerService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!UserUtils.isAuthenticated()) {
            this.logger.log(USER_LOGOUT);
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            this.router.navigate(['/login']);
            return false;
        }

        // record login when user opens the browser (and already logged in)
        this.logger.log(USER_LOGIN);
        // logged in so return true
        return true;
    }
}