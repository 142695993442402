import { Component } from "@angular/core";
import { Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: 'app-confirm',
  template: `
    <h2 *ngIf="title" mat-dialog-title>{{title}}</h2>
    <mat-dialog-content>
      {{message}}
    </mat-dialog-content>
    <mat-dialog-actions class="d-flex align-items-end flex-column">
        <div class="flex-column">
        <button class="btn btn-default mr-2" mat-button mat-dialog-close>{{denyMessage}}</button>
        <button class="btn btn-primary" mat-button [mat-dialog-close]="true">{{confirmMessage}}</button>
      </div>
    </mat-dialog-actions>
    `
})
export class ConfirmDialog {
  title: string;
  message: string;
  confirmMessage: string = 'Yes';
  denyMessage: string = 'No';

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.title = this.data.title;
    this.message = this.data.message;

    if (this.data.confirmMessage)
      this.confirmMessage = this.data.confirmMessage;

    if (this.data.denyMessage)
      this.denyMessage = this.data.denyMessage;
  }
}