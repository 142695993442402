import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ErrorHandler } from '@angular/core';
import { TouchEventModule } from "ng2-events/lib/touch";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AppComponent } from './app.component';
import { LoginComponent } from './user/login/login.component';
import { routing } from './app.routing';
import { AppGuard } from './app.guard';
import { AuthService } from './user/auth/auth.service';
import { AuthInterceptor } from './user/auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SignupComponent } from './user/signup/signup.component';
import { SignupMessageComponent } from './user/signup/signup-message.component';
import { MatDialogModule, MatSnackBar, MatSnackBarModule, GestureConfig } from '@angular/material';
import { RecoverDialogComponent } from './user/recover/recover-dialog.component';
import { ConfirmDialog } from './ui/dialog/confirm-dialog.component';
import { WarningDialogComponent } from './ui/dialog/warning-dialog.component';
import { UIMessageService } from './ui/ui-message.service';
import { ResetComponent } from './user/recover/reset.component';
import { ResetDialogComponent } from './user/recover/reset-dialog/reset-dialog.component';
import { ProfileFormGroupComponent } from './user/profile/profile-form-group.component';
import { ProfileDialogComponent } from './user/profile/profile-dialog.component';
import { PasswordFormGroupComponent } from './user/password/password-form-group.component';
import { UserProfileStore } from './user/class/user-profile.store';
import { UserProjectStore } from './user/class/user-project.store';
import { ConfirmEmailComponent } from './user/confirm-email/confirm-email.component';
import { AppErrorHandler } from './app.error-handler';
import { PreloaderComponent } from './ui/preloader/preloader.component';
import { SudoComponent } from './user/sudo.component';
import { EntryComponent } from './entry.component';
import { HelpDialogComponent } from './help/help-dialog.component';
import { ErrorLogStore } from './error/error-log.store';
import { ErrorService } from './error/error-service';
import { Injector } from '@angular/core';
import { LoadingService } from './loading.service';
import { LoggerService } from './log/logger.service';
import { UserActivityStore } from './data/user-activity.store';
import { ResponsiveBreakpointService } from './services/responsive-breakpoint.service';
import { InviteUserComponent } from './user/invite/invite-user-accept.component';
import { RecaptchaService } from './user/recaptcha/recaptcha.service';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SudoComponent,
    PasswordFormGroupComponent,
    ProfileFormGroupComponent,
    ProfileDialogComponent,
    SignupComponent,
    SignupMessageComponent,
    ResetComponent,
    InviteUserComponent,
    RecoverDialogComponent,
    ConfirmDialog,
    WarningDialogComponent,
    ConfirmEmailComponent,
    PreloaderComponent,
    EntryComponent,
    ResetDialogComponent,
    HelpDialogComponent
  ],
  imports: [
    BrowserModule, FormsModule, ReactiveFormsModule, HttpClientModule,
    routing, NoopAnimationsModule, MatDialogModule, MatSnackBarModule,
    TouchEventModule, RecaptchaModule, PopoverModule.forRoot()
  ],
  providers: [
    ErrorLogStore,
    ErrorService,
    LoadingService,
    LoggerService,
    UserActivityStore,
    ResponsiveBreakpointService,
    RecaptchaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: UIMessageService, useExisting: MatSnackBar },
    AppGuard, AuthService, UserProfileStore, UserProjectStore,
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    // { provide: SCROLL_EVENT_TIME, useValue: 500 }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ProfileDialogComponent,
    SignupComponent,
    SignupMessageComponent,
    RecoverDialogComponent,
    ResetDialogComponent,
    HelpDialogComponent,
    ConfirmDialog,
    WarningDialogComponent
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}

export let InjectorInstance: Injector;
