import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'fs-inviteUser',
    templateUrl: './invite-user-accept.component.html'
})
export class InviteUserComponent {

    accepted: boolean = false;

    returnUrl: string = '/';
    token: string;
    error: boolean = false;
    errorMessage: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
    ) {
        this.token = this.route.snapshot.paramMap.get('token');

        this.authService.acceptInvite(this.token).subscribe((confirmed: boolean) => {
            if (confirmed)
                this.moveToLoginScreen();
            else
                this.accepted = true;
        }, error => {
            this.error = true;
            this.errorMessage = error.error.message;
        });
    }

    moveToLoginScreen() {
        this.router.navigate(['/login'], {
            queryParams: {
                token: this.token
            }
        });
    }
}