// max id: 41

// fusion menu
// export const CLICK_FUSION_BUTTON = 1;
export const CREATE_NEW_MODEL = 2;
export const LOAD_PROJECT = 3;
export const LOAD_MODEL_FROM_LIBRARY = 4;
export const LEARN_MODEL = 5;
export const LOAD_LEARNED_MODEL = 6;

// plugins
export const ACCESS_ADMISSIBLE_SETS = 9;
export const QUERY_BACKDOOR_ADMISSIBILITY = 10;
export const ACCESS_INSTRUMENTAL_VARIABLES = 11;
export const QUERY_IV_ADMISSIBILITY = 36;
export const ACCESS_FRONTDOOR_ADMISSIBLE_SETS = 40;
export const QUERY_FRONTDOOR_ADMISSIBILITY = 41;

export const QUERY_D_SEPARATION = 12;
export const QUERY_DO_CALCULUS = 35;
export const QUERY_DO_CALCULUS_INSPECTOR = 37;
export const ACCESS_CAUSAL_PATHS = 13;
export const ACCESS_CONFOUNDING_PATHS = 14;
export const ACCESS_BIASING_PATHS = 15;

export const ACCESS_CONDITIONAL_INDEPENDENCIES = 16;
export const ACCESS_VERMA_CONSTRAINTS = 17;

export const ACCESS_EXCLUSION_RESTRICTIONS = 18;
export const ACCESS_INDEPENDENCE_RESTRICTIONS = 19;

// user
export const USER_LOGIN = 20;
export const USER_LOGOUT = 21;
export const USER_RESET_PASSWORD = 22;
export const USER_REQUEST_RECOVER_PASSWORD = 23;
export const USER_EDIT_PROFILE = 24;
export const USER_RECOVER_PASSWORD = 38;

// export
export const EXPORT_GRAPH = 25;
export const EXPORT_DERIVATION = 33;
export const EXPORT_ESTIMATION = 34;

// editor
export const DOWNLOAD_GRAPH_TEXT = 26;
export const UPLOAD_GRAPH_TEXT = 27;

// graph
export const ADD_EMPTY_GRAPH = 28;
export const DELETE_GRAPH = 29;

// compute
export const COMPUTE_CAUSAL_EFFECT = 7;

// derivation
export const DERIVATION_LOAD_GRAPH = 30;
export const DERIVATION_ACCESS = 31;

// estimation
export const ESTIMATE_CAUSAL_EFFECT = 8;
export const ESTIMATION_ACCESS = 32;

// conditional independence test
export const MATCH_CONDITIONAL_INDEPENDENCE_FROM_DATASET_AGAINST_GRAPH = 39;