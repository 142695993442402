import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { UIMessageService } from '../../../ui/ui-message.service';
import { PasswordValidator } from '../../password/password.validator';

@Component({
  selector: 'fs-resetDialog',
  templateUrl: './reset-dialog.component.html',
  styleUrls: ['./reset-dialog.component.css']
})
export class ResetDialogComponent implements OnInit {

  resetForm: FormGroup;
  loading = false;
  message: string;
  error: boolean = false;

  errors = {
    emptyCurrentPassword: 'Please specify your current password.',
    emptyNewPassword: 'Please specify your new password.',
    newPasswordRepeat: 'Please type the password again.',
    passwordMatch: 'Passwords do not match.'
  };

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResetDialogComponent>,
    private messages: UIMessageService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  get currentPassword() {
    return this.resetForm ? this.resetForm.get('current') : null;
  }

  get newPassword() {
    return this.resetForm ? this.resetForm.get('password') : null;
  }

  get passwordRepeat() {
    return this.resetForm ? this.resetForm.get('password_repeat') : null;
  }

  reset() {
    let currentPassword: string = this.currentPassword.value;
    let newPassword: string = this.newPassword.value;

    this.loading = true;

    this.authService.resetPassword(currentPassword, newPassword).subscribe(data => {
      this.loading = false;

      this.messages.open('Password changed.', '', { duration: 5000, panelClass: ['text-center', 'd-flex', 'justify-content-center'] });
      this.dialogRef.close();
    }, error => {
      this.loading = false;

      if (error.error instanceof Array)
        this.newPassword.setErrors({ 'server': error.error[0].message });
      else {
        if (error.error.message)
          this.currentPassword.setErrors({ 'server': error.error.message });
        else
          this.newPassword.setErrors({ 'server': error.error });
      }
    });
  }

  private createForm() {
    this.resetForm = this.fb.group({
      current: ['', Validators.required],
      password: ['', Validators.required],
      password_repeat: ['', Validators.required]
    }, {
      validator: PasswordValidator.matchPassword
    });
  }
}
