import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'fs-warningDialog',
  templateUrl: './warning-dialog.component.html'
})
export class WarningDialogComponent {

  title: string = '';
  message: string | string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  isString(obj: any) {
    return typeof obj == 'string';
  }
}
