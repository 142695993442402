import { FormGroup, AbstractControl } from "@angular/forms";

export class FormUtils {

    static setErrors(errors: { field: string, message: string | any[] }[], fg: FormGroup) {
        for (let error of errors) {
            let formField: AbstractControl = fg.get(error.field);

            if (formField instanceof FormGroup && error.message instanceof Array)
                this.setErrors(error.message, <FormGroup>fg.get(error.field));
            else {
                if (formField)
                    formField.setErrors({ 'server': error.message });
                else {
                    for (let controlName in fg.controls) {
                        let control: AbstractControl = fg.get(controlName);

                        if (control instanceof FormGroup) {
                            let subFormField: AbstractControl = control.get(error.field);

                            if (subFormField)
                                subFormField.setErrors({ 'server': error.message });
                        }
                    }
                }
            }
        }
    }

    static touchRequiredFields(fg: FormGroup) {
        for (let controlName in fg.controls) {
            let control: AbstractControl = fg.get(controlName);

            if (control instanceof FormGroup) {
                for (let subControlName in control.controls) {
                    let subControl: AbstractControl = control.get(subControlName);

                    if (subControl.validator) {
                        const validator = subControl.validator({} as AbstractControl);

                        if (validator && validator.required && subControl.untouched)
                            subControl.markAsTouched();
                    }
                }
            } else {
                if (control.validator) {
                    const validator = control.validator({} as AbstractControl);

                    if (validator && validator.required && control.untouched)
                        control.markAsTouched();
                }
            }
        }
    }
}