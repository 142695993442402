import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../auth/auth.service";

@Component({
    selector: 'app-recover',
    templateUrl: './recover-dialog.component.html',
    styleUrls: ['./recover-dialog.component.css']
})
export class RecoverDialogComponent {
    recoverForm: FormGroup;
    message: string;
    error: boolean = false;
    processing: boolean = false;

    errors = {
        emailEmpty: 'Please specify your email address.',
        emailFormat: 'Please specify your email address in correct format.',
        email: 'Sorry, we are unable to reset password for the provided email address. Please, try again later or contact us if the problem persists.'
    };

    constructor(
        private authService: AuthService,
        private fb: FormBuilder
    ) {
        this.createForm();
        this.setupEvents();
    }

    get email() {
        return this.recoverForm.get('email');
    }

    recover() {
        this.message = '';
        this.processing = true;

        this.authService.requestPasswordReset(this.email.value).subscribe(message => {
            this.processing = false;
            this.error = false;
            this.message = message;
        }, error => {
            this.processing = false;

            switch (error.status) {
                case 400:
                case 401:
                    this.message = error.error.message;
                    break;
                default:
                    this.message = this.errors.email;
                    break;
            };

            this.error = true;
        }
        );
    }

    private createForm() {
        this.recoverForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    private setupEvents() {
        if (!this.recoverForm) return;

        this.recoverForm.valueChanges.subscribe(values => {
            this.message = '';
        });
    }
}