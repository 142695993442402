import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'confirmEmail',
  templateUrl: './confirm-email.component.html'
})
export class ConfirmEmailComponent implements OnInit {

  loading = true;
  returnUrl: string;
  message: string;
  error: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    let email: string = this.route.snapshot.queryParamMap.get('email');
    let hash: string = this.route.snapshot.queryParamMap.get('hash');

    // confirmEmail login status
    // get return url from route parameters or default to '/'
    this.returnUrl = '/';

    this.authService.confirmEmail(email, hash).subscribe(_ => {
      this.loading = false;
      this.error = false;
      this.message = 'Your email address has been verified. Please follow the link to login into Causal Fusion.'
    }, (error) => {
      this.loading = false;
      this.error = true;
      this.message = error.error.message;
    });
  }
}
