import { Injectable } from "@angular/core";
import { of, throwError } from "rxjs";
import { UserActivity } from "../data/user-activity";
import { UserActivityStore } from "../data/user-activity.store";
import { catchError } from "rxjs/operators";
import { User } from "../user/class/user";

@Injectable()
export class LoggerService {

    constructor(
        private store: UserActivityStore,
    ) { }

    log(action_id: number, metadata?: any) {
        this.log$(action_id, metadata).subscribe(_ => { }, error => { });
    }

    log$(action_id: number, metadata?: any) {
        let currentUser: string = localStorage.getItem('currentUser');

        if (!currentUser)
            return of(null);

        let user: User = JSON.parse(currentUser);

        if (user && user['sudo_id']) {
            if (!metadata)
                metadata = {};

            metadata['sudo_id'] = user['sudo_id'];
        }

        let log: UserActivity = {
            id: undefined,
            action_id: action_id,
            metadata: metadata ? JSON.stringify(metadata) : ''
        };

        return this.store.insert(log).pipe(catchError(_ => {
            return throwError(_);
        }));
    }
}