import { Component, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { UserForm } from '../class/user-form';
import { UIMessageService } from '../../ui/ui-message.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProfileFormGroupComponent } from '../profile/profile-form-group.component';
import { PasswordFormGroupComponent } from '../password/password-form-group.component';
import { FormUtils } from '../../shared/form-utils';
import { SignupMessageComponent } from './signup-message.component';
import { InvitedUser } from '../class/invited-user';
import { HttpErrorResponse } from '@angular/common/http';
import { distinctUntilChanged } from 'rxjs/operators';
import { defaultErrorMessage } from '../../error/class/error-message';
import { ObjectUtils } from '../../commons/object-utils';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['signup.component.css']
})
export class SignupComponent {

  private _profileGroup: ProfileFormGroupComponent;
  private _passwordGroup: PasswordFormGroupComponent;
  private token: string;

  signUpForm: FormGroup;
  wasSubmitted: boolean = false;
  processing: boolean = false;
  loaded: boolean = false;
  invalidated: boolean = false;
  invalidFormNames: string[] = [];
  invalidFormIntroMessage: string = '';
  invalidFormMessage: string = '';

  errors = {
    username: 'Please specify your username.',
    emailEmpty: 'Please specify your email address.',
    emailFormat: 'Please specify your email address in correct format.',
    space: 'Please do not include a space.'
  };

  serverErrors = {
    username: '',
    email: '',
    general: ''
  };

  /**
   * Previous form values are stored to check if form values was actually changed in valueChanges event
   */
  private previousValues = {};

  @ViewChild(ProfileFormGroupComponent) set profileGroup(pg: ProfileFormGroupComponent) {
    this._profileGroup = pg;

    if (pg)
      this.signUpForm.addControl('profile', pg.profileFormGroup);
  }
  @ViewChild(PasswordFormGroupComponent) set passwordGroup(pg: PasswordFormGroupComponent) {
    this._passwordGroup = pg;

    if (pg)
      this.signUpForm.addControl('password', pg.passwordFormGroup);
  }

  constructor(
    private authService: AuthService,
    private messages: UIMessageService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SignupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.createForm();
    this.setupEvents();
  }

  get username(): AbstractControl { return this.signUpForm.get('username'); }
  get email(): AbstractControl { return this.signUpForm.get('email'); }

  ngAfterViewInit() {
    this.loadProfileWithToken();
  }

  signup() {
    this.updateInvalidFormMessage();

    if (!this.validate() || this.signUpForm.invalid) {
      FormUtils.touchRequiredFields(this.signUpForm);
      return;
    };

    let data = this.signUpForm.value;
    data['email'] = this.email.value;
    data['password'] = data.password.password;
    data['password_repeat'] = data.password.password_repeat;

    this.wasSubmitted = true;
    this.processing = true;

    this.serverErrors = { username: '', email: '', general: '' };

    setTimeout(_ => {
      this.authService.signup(data, this.token).subscribe((signup: UserForm) => {
        this.processing = false;

        this.messages.openFromComponent(SignupMessageComponent, { duration: 8000, panelClass: ['text-center', 'd-flex', 'justify-content-center'] });

        this.dialogRef.close();
      }, (error: HttpErrorResponse) => {
        this.processing = false;

        setTimeout(_ => {
          this.invalidated = true;

          if (error instanceof HttpErrorResponse) {
            if (error.error instanceof Array) {
              for (let err of error.error) {
                if (this.serverErrors[err.field] !== undefined)
                  this.serverErrors[err.field] = err.message;
              }
            } else {
              if (error.error !== undefined && error.error.message)
                this.serverErrors.general = error.error.message;
              else
                this.serverErrors.general = defaultErrorMessage;
            }
          }

          // if (error.error && error.error['code'] !== undefined)
          //   this.serverErrors.general = defaultErrorMessage;

          this.updateInvalidFormMessage();
        }, 1);
      });
    }, 1);
  }

  private updateInvalidFormMessage() {
    this.invalidFormNames = [];

    if (!this.signUpForm) {
      this.invalidFormIntroMessage = null;
      this.invalidFormMessage = null;

      return;
    }

    // specify in order
    if (this.username && (this.username.invalid || this.serverErrors['username']))
      this.invalidFormNames.push('username');

    if (this.email && (this.email.invalid || this.serverErrors['email']))
      this.invalidFormNames.push('email');

    this.addInvalidFormNames(this._passwordGroup.passwordFormGroup.controls);
    this.addInvalidFormNames(this._profileGroup.profileFormGroup.controls);

    if (this.invalidFormNames.length == 0) {
      this.invalidFormIntroMessage = null;
      this.invalidFormMessage = null;
    } else if (this.invalidFormNames.length == 1) {
      this.invalidFormIntroMessage = 'Please specify the following field correctly:';
      this.invalidFormMessage = this.invalidFormNames.map(n => this.formNameToLabel(n)).join(', ') + '.';
    } else {
      this.invalidFormIntroMessage = 'Please specify the following fields correctly:';
      this.invalidFormMessage = this.invalidFormNames.map(n => this.formNameToLabel(n)).join(', ') + '.';
    }
  }

  private addInvalidFormNames(controls: { [key: string]: AbstractControl }) {
    for (let name in controls) {
      let control = controls[name];

      if (control instanceof FormGroup) {
        let subControls = control.controls;

        for (let subName in subControls) {
          let subControl = subControls[subName];

          if (subControl.invalid)
            this.invalidFormNames.push(subName);
        }
      } else {
        if (control.invalid)
          this.invalidFormNames.push(name);
      }
    }
  }

  private formNameToLabel(name: string): string {
    switch (name) {
      case 'username':
        return 'Username';
      case 'email':
        return 'E-mail';
      case 'first_name':
        return 'First Name';
      case 'last_name':
        return 'Last Name';
      case 'password':
        return 'Password';
      case 'password_repeat':
        return 'Re-type Password';
      case 'country_code':
        return 'Country';
      case 'institution':
        return 'Institution / Company';
      case 'position':
        return 'Position';
      default:
        return '';
    }
  }

  private validate(): boolean {
    if (this.username) {
      let username: string = this.username.value;

      if (username == '') {
        this.username.setErrors({ required: this.errors.username });
        return false;
      }

      if (username.indexOf(' ') != -1) {
        this.username.setErrors({ space: this.errors.space });
        return false;
      }

      this.username.setErrors(null);
    }

    return true;
  }

  private createForm() {
    this.signUpForm = this.fb.group({
      username: ['', [Validators.required, this.validateUsername.bind(this)]],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  private validateUsername(control: AbstractControl): ValidationErrors {
    let username: string = control.value;

    if (username == '')
      return { required: this.errors.username };

    if (username && username.indexOf(' ') != -1)
      return { space: this.errors.space };

    return null;
  }

  private setupEvents() {
    if (!this.signUpForm) return;

    this.signUpForm.valueChanges.pipe(distinctUntilChanged()).subscribe(values => {
      if (this.invalidFormMessage)
        this.updateInvalidFormMessage();

      let diff = ObjectUtils.diff(this.previousValues, values);
      let changed: boolean = Object.keys(diff).length > 0;

      this.previousValues = values;

      if (changed) {
        this.invalidated = false;

        this.serverErrors = { username: '', email: '', general: '' };
      }
    });
  }

  private loadProfileWithToken() {
    this.token = this.data.token;

    if (this.token) {
      setTimeout(_ => {
        this.authService.getInvitedUserWithInviteToken(this.token).subscribe((invitedUser: InvitedUser) => {
          this.loaded = true;

          let emailForm: AbstractControl = this.email;
          let profileGroup = this.signUpForm.get('profile');

          // disallow modifying email
          if (emailForm) {
            emailForm.setValue(invitedUser.email);
            emailForm.disable();
          }

          if (profileGroup) {
            let firstNameForm: AbstractControl = profileGroup.get('first_name');
            let lastNameForm: AbstractControl = profileGroup.get('last_name');
            let positionForm: AbstractControl = profileGroup.get('position');

            if (firstNameForm)
              firstNameForm.setValue(invitedUser.first_name);

            if (lastNameForm)
              lastNameForm.setValue(invitedUser.last_name);

            if (positionForm)
              positionForm.setValue(invitedUser.position);
          }
        }, error => {
          if (error instanceof HttpErrorResponse) {
            let emailForm: AbstractControl = this.email;

            if (emailForm)
              emailForm.setErrors({ 'server': error.error.message });
          }
        });
      }, 1);
    }

    setTimeout(_ => {
      this.loaded = true;
    }, 1);
  }
}
