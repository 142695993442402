import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class LoadingService {

    private _loadingState: boolean = false;
    loadingStateChange: EventEmitter<boolean> = new EventEmitter();

    get loadingState(): boolean {
        return this._loadingState;
    }
    set loadingState(ls: boolean) {
        this._loadingState = ls;
        this.loadingStateChange.emit(ls);
    }
}