import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { UIMessageService } from '../../ui/ui-message.service';
import { MatDialogRef } from '@angular/material';
import { ProfileFormGroupComponent } from './profile-form-group.component';
import { FormUtils } from '../../shared/form-utils';
import { UserProfile } from '../class/user-profile';

@Component({
    selector: 'app-profileDialog',
    templateUrl: './profile-dialog.component.html',
    styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent {

    wasSubmitted: boolean = false;
    processing: boolean = false;
    loaded: boolean = false;
    profile: UserProfile;
    private _profileFormGroup: ProfileFormGroupComponent;

    @ViewChild(ProfileFormGroupComponent) set profileFormGroup(pfg: ProfileFormGroupComponent) {
        this._profileFormGroup = pfg;
        if (pfg)
            this.profileForm.setValue(this.profile);
    }

    constructor(
        private authService: AuthService,
        private messages: UIMessageService,
        private dialogRef: MatDialogRef<ProfileDialogComponent>,
        private cdr: ChangeDetectorRef
    ) {
        this.loadProfile();
    }

    get profileForm(): FormGroup {
        return this._profileFormGroup ? this._profileFormGroup.profileFormGroup : null;
    }

    saveProfile() {
        if (!this._profileFormGroup.valid) return;

        this.wasSubmitted = true;
        this.processing = true;

        this.authService.saveProfile(this._profileFormGroup.value).subscribe((userData: UserProfile) => {
            this.processing = false;

            this.openMessage('User profile saved.');

            this.dialogRef.close();
        }, (errResp) => {
            this.processing = false;

            FormUtils.setErrors(errResp.error, this.profileForm);
        });
    }

    showChangePasswordDialog() {
        this.dialogRef.close(true);
    }

    openMessage(message: string, duration: number = 3000) {
        this.messages.open(message, '', { duration: duration, panelClass: ['text-center', 'd-flex', 'justify-content-center'] });
    }

    private loadProfile() {
        this.authService.getProfile().subscribe((profile) => {
            this.loaded = true;
            this.profile = profile;
            this.cdr.detectChanges();
        });
    }
}
