import { Routes, RouterModule } from '@angular/router';

import { AppGuard } from './app.guard';
import { LoginComponent } from './user/login/login.component';
import { ResetComponent } from './user/recover/reset.component';
import { InviteUserComponent } from './user/invite/invite-user-accept.component';
import { ConfirmEmailComponent } from './user/confirm-email/confirm-email.component';
import { SudoComponent } from './user/sudo.component';
import { EntryComponent } from './entry.component';

const appRoutes: Routes = [
    { path: '', component: EntryComponent },
    { path: 'app', canActivate: [AppGuard], loadChildren: 'app/fusion/fusion.module#FusionModule', },
    { path: 'login', component: LoginComponent },
    { path: 'sudo/:token', component: SudoComponent },
    { path: 'reset/:token', component: ResetComponent },
    { path: 'invite/:token', component: InviteUserComponent },
    { path: 'confirm-email', component: ConfirmEmailComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

export const routing = RouterModule.forRoot(appRoutes);