import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { SignupComponent } from '../signup/signup.component';
import { RecoverDialogComponent } from '../recover/recover-dialog.component';
import { UserUtils } from '../user-utils';
import { ResponsiveBreakpointService } from '../../services/responsive-breakpoint.service';
import { RecaptchaService } from '../recaptcha/recaptcha.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  private savedRememberMe: boolean;
  private signupToken: string;
  private unsubscribe: Subject<void> = new Subject();
  recaptchaChecked: boolean = false;
  recaptchaSuccess: boolean = false;
  inputChanged: boolean = false;

  loginForm: FormGroup;
  loading = false;
  returnUrl: string;
  currentBreakpointClass: string = '';

  errors = {
    username: 'Please specify your username.',
    password: 'Please specify your password.',
    all: 'Please specify your username and password.',
    incorrect: 'Incorrect username or password.',
    space: 'Please do not include a space.',
    recaptchaChecked: 'Please check the checkbox.',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private responsiveBreakpointService: ResponsiveBreakpointService,
    private recaptchaService: RecaptchaService
  ) {
    this.initializeParameters();
    this.createForm();
    this.setupEvents();
  }

  ngAfterViewInit() {
    if (this.signupToken && this.signupToken != '')
      this.openSignUp();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get username(): AbstractControl { return this.loginForm.get('username'); }
  get password(): AbstractControl { return this.loginForm.get('password'); }
  get rememberMe(): AbstractControl { return this.loginForm.get('remember_me'); }

  get valid(): boolean {
    return this.loginForm != null && this.username && this.password
      && (
        (!this.loginForm.touched && !this.username.touched && !this.password.touched)
        || ((this.loginForm.touched || this.username.touched || this.password.touched) && this.loginForm.valid)
      );
  }

  resolved(response: string) {
    this.loading = true;

    this.recaptchaSuccess = false;

    // the token is expired
    if (response == null) {
      this.recaptchaChecked = false;
      this.loading = false;
      return;
    }

    this.recaptchaChecked = true;

    this.recaptchaService.verify(response).pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.recaptchaSuccess = result.success;

      this.login();
    }, error => {
      this.loading = false;
    });
  }
  login() {
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;

    this.authService.login(this.username.value, this.password.value, this.rememberMe.value).pipe(takeUntil(this.unsubscribe)).subscribe(_ => {
      this.loading = false;

      this.router.navigate([this.returnUrl]);
    }, error => {
      this.loading = false;

      let message: string = '';

      if (error.error && error.error.message)
        message = error.error.message;
      else
        message = this.errors.incorrect;

      this.username.setErrors({ 'server': message });
      this.password.setErrors({ 'server': message });
    });
  }

  openSignUp() {
    this.dialog.open(SignupComponent, {
      width: '600px',
      disableClose: true,
      data: {
        token: this.signupToken
      }
    });
  }

  openRecoverDialog() {
    this.dialog.open(RecoverDialogComponent, {
      width: '600px',
      height: '250px',
      disableClose: true
    });
  }

  private initializeParameters() {
    this.recaptchaSuccess = false;
    this.signupToken = this.route.snapshot.queryParams['token'];

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.savedRememberMe = UserUtils.getRememberMe();
  }

  private createForm() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, this.validateUsername.bind(this)]],
      password: ['', Validators.required],
      remember_me: [this.savedRememberMe !== undefined ? this.savedRememberMe : false]
    });
  }

  private setupEvents() {
    // periodically check whether the user has logged in
    setInterval(_ => {
      if (this.router.url == '/login') {
        if (UserUtils.isAuthenticated()) {
          this.authService.validateUserStatus().subscribe((valid: boolean) => {
            if (valid)
              this.router.navigate([this.returnUrl]);
          }, error => {

          });
        }
      }
    }, 5000);

    this.loginForm.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(values => {
      if (!this.inputChanged)
        this.inputChanged = true;

      // by default, remove server-side error message on any input change
      if (this.username && this.username.errors && this.username.errors.server !== undefined)
        this.username.setErrors(null);

      if (this.password && this.password.errors && this.password.errors.server !== undefined)
        this.password.setErrors(null);

      UserUtils.setRememberMe(values['remember_me']);
    });

    this.responsiveBreakpointService.resize.pipe(takeUntil(this.unsubscribe)).subscribe(breakpointClass => {
      this.setCurrentBreakpointClass(breakpointClass);
    });
  }

  private validateUsername(control: AbstractControl): null | ValidationErrors {
    let username: string = control.value;

    if (username && username.indexOf(' ') != -1)
      return { space: this.errors.space };

    return null;
  }

  private setCurrentBreakpointClass(currentClass: string) {
    if (currentClass === undefined) return;

    this.currentBreakpointClass = currentClass;
  }
}
