import { Component, AfterViewInit } from "@angular/core";
import { AuthService } from "./user/auth/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-entry',
    template: ''
})
export class EntryComponent implements AfterViewInit {

    constructor(
        private auth: AuthService,
        private router: Router
    ) { }

    ngAfterViewInit(): void {
        let user = this.auth.user;

        if (!user || !user.id)
            this.router.navigate(['/login']);
        else {
            this.auth.validateUserStatus().subscribe((valid: boolean) => {
                if (valid)
                    this.router.navigate(['/app']);
                else
                    this.router.navigate(['/login']);
            }, error => {
                this.router.navigate(['/login']);
            });
        }
    }
}