export class UserUtils {

    static isAuthenticated(): boolean {
        if (!localStorage.getItem('currentUser'))
            return false;

        if (!this.getRememberMe()) {
            if (!sessionStorage.getItem('USER_TOKEN') || !JSON.parse(sessionStorage.getItem('USER_TOKEN')))
                return false;

            let token = JSON.parse(sessionStorage.getItem('USER_TOKEN'));
            let user = JSON.parse(localStorage.getItem('currentUser'));

            if (!user || !user.token || user.token !== token)
                return false;
        }

        return true;
    }

    static getRememberMe(): boolean {
        let currentRememberMe: string = localStorage.getItem('rememberMe');
        let rememberMe: boolean = currentRememberMe ? JSON.parse(currentRememberMe) : false;

        if (rememberMe == null)
            rememberMe = false;

        return rememberMe;
    }

    static setRememberMe(rememberMe: boolean) {
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
    }
}