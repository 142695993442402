import { AbstractControl } from "@angular/forms";

// reference:
// https://scotch.io/@ibrahimalsurkhi/match-password-validation-with-angular-2
export class PasswordValidator {

    static matchPassword(AC: AbstractControl) {
        let password: string = AC.get('password').value;
        let confirmPassword: string = AC.get('password_repeat').value;

        if (password != confirmPassword || password == '')
            AC.get('password_repeat').setErrors({ passwordMismatch: 'Passwords do not match.' });
        else
            AC.get('password_repeat').setErrors(null);
    }
}