import { Component } from '@angular/core';
// import { MatDialog } from '@angular/material';

@Component({
  selector: 'fs-helpDialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {

  tabs: string[] = [
    'About',
    'Team',
    'Documentation'
  ];
  currentTab: string = 'About';

  constructor() { }

  setTab(activeTab: string) {
    this.currentTab = activeTab;
  }

  openLinkInNewTab(url: string) {
    window.open(url, '_blank');
  }
}
