import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, of, } from "rxjs";
import { map, tap, catchError, switchMap, } from 'rxjs/operators';
import { UserProfile } from '../class/user-profile';
import { UserForm } from '../class/user-form';
import { User } from '../class/user';
import { UserProfileStore } from '../class/user-profile.store';
import { LoggerService } from '../../log/logger.service';
import { UserUtils } from '../user-utils';
import { InvitedUser } from '../class/invited-user';
import { USER_EDIT_PROFILE } from '../../log/user-activity-map';

@Injectable()
export class AuthService {

  private _user: User;

  constructor(
    private http: HttpClient,
    private router: Router,
    private profileStore: UserProfileStore,
    private logger: LoggerService
  ) {
    this._user = JSON.parse(localStorage.getItem('currentUser'));

    // force logout if user id is outdated (numeric)
    if (this.user && !isNaN(this.user.id)) {
      this._user = null;
      this.logout();
    }

    this.setupEvents();
  }

  get user(): User {
    return this._user;
  }

  login(username: string, password: string, rememberMe: boolean): Observable<User> {
    return this.http.post<User>('api/authenticate', { username: username, password: password }).pipe(
      map((user: User) => {
        UserUtils.setRememberMe(rememberMe);

        if (rememberMe === false)
          sessionStorage.setItem('USER_TOKEN', JSON.stringify(user.token));
        else
          sessionStorage.removeItem('USER_TOKEN');

        this.storeUserData(user);

        return user;
      }), catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * we assume that logging is completed before calling this function.
   */
  logout() {
    if (!UserUtils.getRememberMe() && sessionStorage.getItem('USER_TOKEN')) {
      localStorage.setItem('REMOVE_TOKEN', Date.now().toString());
      localStorage.removeItem('REMOVE_TOKEN');
    }

    // remove user from local storage to log user out
    if (localStorage.getItem('currentUser'))
      localStorage.removeItem('currentUser');

    if (this.router.url != '/login')
      this.router.navigate(['/login']);
  }

  validateUserStatus(): Observable<boolean> {
    if (!this.user)
      return throwError('');

    return this.http.post<boolean>('api/validate-user-status', { id: this.user.id });
  }

  sudo(token: string): Observable<User> {
    return this.http.post<User>('api/sudo', { token: token }).pipe(map((user: User) => {
      // let user log in as if 'remember me' was unchecked
      UserUtils.setRememberMe(false);

      sessionStorage.setItem('USER_TOKEN', JSON.stringify(user.token));

      this.storeUserData(user);

      return user;
    }), catchError(error => {
      return throwError(error);
    }));
  }

  signup(model: UserForm, token?: string): Observable<UserForm> {
    return this.http.post<UserForm>('api/signup', { form: model, token: token });
  }

  invite(users: InvitedUser[]) {
    return this.http.post('api/invite', users);
  }

  acceptInvite(token: string): Observable<boolean> {
    return this.http.get<boolean>('api/accept-invite?token=' + token);
  }

  getInvitedUserWithInviteToken(token: string): Observable<InvitedUser> {
    return this.http.get<InvitedUser>('api/get-invited-user?token=' + token);
  }

  getMaxInvites(): Observable<number> {
    return this.http.post<number>('api/get-max-invites', { id: this.user.id });
  }

  getProfile(): Observable<UserProfile> {
    return this.profileStore.get(this.user.id);
  }

  saveProfile(model: UserProfile): Observable<UserProfile> {
    return this.profileStore.update(model).pipe(tap(u => {
      if (this.user) {
        this.user.firstName = u.first_name;
        this.user.lastName = u.last_name;
        localStorage.setItem('currentUser', JSON.stringify(this.user));
      }
    }), switchMap(profile => {
      return this.logger.log$(USER_EDIT_PROFILE, { success: 1 }).pipe(switchMap(_ => {
        return of(profile);
      }), catchError(_ => {
        return of(profile);
      }));
    }), catchError(_ => {
      return this.logger.log$(USER_EDIT_PROFILE, { success: 0 }).pipe(switchMap(_ => {
        return throwError(_);
      }), catchError(_ => {
        return throwError(_);
      }));
    }));
  }

  /**
   * Request for recovering password
   * Logging is done on the server
   * @param email 
   */
  requestPasswordReset(email: string): Observable<string> {
    return this.http.post<string>('api/request-password-token', { email: email });
  }

  canReset(token: string): Observable<void> {
    return this.http.get<void>('api/recover-password?token=' + token);
  }

  /**
   * Recover password
   * Logging is done on the server
   * @param token 
   * @param password 
   */
  resetPasswordWithToken(token: string, password: string): Observable<string> {
    return this.http.post<string>('api/recover-password?token=' + token, { password: password });
  }

  setPassword(password: string): Observable<string> {
    return this.http.post<string>('api/change-password', { password: password });
  }

  /**
   * Reset password
   * Logging is done on the server
   * @param previousPassword 
   * @param newPassword 
   */
  resetPassword(previousPassword: string, newPassword: string) {
    return this.http.post<string>('api/reset-password', { previous: previousPassword, password: newPassword });
  }

  confirmEmail(email: string, hash: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('email', email);
    params = params.append('hash', hash);

    return this.http.get<string>('api/confirm-email', { params: params });
  }

  private setupEvents() {
    // periodically check whether the user has logged in
    setInterval(_ => {
      if (this.router.url == '/app') {
        if (!UserUtils.isAuthenticated())
          this.logout();

        this.validateUserStatus().subscribe((valid: boolean) => {
          if (!valid)
            this.logout();
        }, error => {
          this.logout();
        });
      }
    }, 30000);
  }

  private storeUserData(user: User) {
    // login successful if there's a jwt token in the response
    if (user && user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      this._user = user;
      localStorage.setItem('currentUser', JSON.stringify(user));
    }
  }
}
