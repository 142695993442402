import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidator } from './password.validator';

@Component({
    selector: 'app-passwordFormGroup',
    templateUrl: './password-form-group.component.html',
    styleUrls: ['./password-form-group.component.css']
})
export class PasswordFormGroupComponent {

    passwordFormGroup: FormGroup;
    passwordMatch: boolean = true;

    errors = {
        emptyPassword: 'Please specify your password.',
        passwordRepeat: 'Please type the password again.',
        passwordMatch: 'Passwords do not match.'
    };

    constructor(
        private fb: FormBuilder
    ) {
        this.createForm();
    }

    get password() { return this.passwordFormGroup.get('password'); }
    get password_repeat() { return this.passwordFormGroup.get('password_repeat'); }

    private createForm() {
        this.passwordFormGroup = this.fb.group({
            'password': ['', Validators.required],
            'password_repeat': ['', Validators.required],
        }, {
            validator: PasswordValidator.matchPassword
        });
    }
}