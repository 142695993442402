import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class UIMessageService {
    dismiss() { };
    open(message: string, action?: string, config?: MessageConfiguration): MessageRef { return null };
    openFromComponent<T>(component, config?: MessageConfiguration): MessageRef { return null };
}

export interface MessageRef {
    afterDismissed(): Observable<void>;
    afterOpened(): Observable<void>;
    closeWithAction();
    dismiss();
    onAction(): Observable<void>;
}

export interface MessageConfiguration {
    /** Time in milliseconds to wait before automatically dismiss the message */
    duration: number;
    // extra css class to be added to the snack bar container
    panelClass?: string | string[];
}