import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UIMessageService } from '../../ui/ui-message.service';
import { PasswordFormGroupComponent } from '../password/password-form-group.component';

@Component({
  selector: 'reset',
  templateUrl: './reset.component.html'
})
export class ResetComponent implements OnInit {

  resetForm: FormGroup;
  processing = false;
  returnUrl: string;
  token: string;
  message: string;
  error: boolean = false;
  canReset: boolean = false;

  @ViewChild(PasswordFormGroupComponent) set passwordGroup(pg: PasswordFormGroupComponent) {
    if (pg)
      this.resetForm.addControl('password', pg.passwordFormGroup);
  }

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private fb: FormBuilder,
    private messages: UIMessageService
  ) { }

  get password() { return this.resetForm && this.resetForm.get('password') ? this.resetForm.get('password').get('password') : null; }
  get password_repeat() { return this.resetForm && this.resetForm.get('password') ? this.resetForm.get('password').get('password_repeat') : null; }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.createForm();
    // reset login status
    // get return url from route parameters or default to '/'
    this.returnUrl = '/';
    this.authService.canReset(this.token).subscribe(_ => {
      this.error = false;
      this.canReset = true;
    }, (error) => {
      this.error = true;

      this.message = error.error.message;
    });
  }

  reset() {
    if (this.resetForm.invalid) return;

    this.processing = true;

    this.authService.resetPasswordWithToken(this.token, this.password.value).subscribe(_ => {
      this.processing = false;

      this.authService.logout();
      this.messages.open('Password changed. You can use your new password to login now.', '', { duration: 5000, panelClass: ['text-center', 'd-flex', 'justify-content-center'] });
    }, error => {
      this.processing = false;

      if (error.error instanceof Array)
        this.password.setErrors({ 'server': error.error[0].message });
      else
        this.password.setErrors({ 'server': error.error });
    });
  }

  private createForm() {
    this.resetForm = this.fb.group({});
  }
}
