import { Injectable } from '@angular/core';
import { Observable, fromEvent } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';

@Injectable()
export class ResponsiveBreakpointService {

  resize: Observable<string>;
  private _resizeSubject: BehaviorSubject<string>;

  constructor() {
    this._resizeSubject = new BehaviorSubject(this.getWindowSizeClass());
    this.resize = this._resizeSubject.asObservable();

    fromEvent(window, 'resize').pipe(map(this.getWindowSizeClass.bind(this))).subscribe(this._resizeSubject);
  }

  get currentWidth(): number {
    return window.innerWidth;
  }

  getWindowSizeClass() {
    return this.widthToClass(window.innerWidth);
  };

  private widthToClass(width: number): string {
    if (width < 1280)
      return 'xs';
    // else if (width >= 800 && width < 1200)
    else if (width >= 1280 && width < 1400)
      return 'sm';
    // else if (width >= 1200 && width < 1320)
    else if (width >= 1400 && width < 1600)
      return 'md';
    // else if (width >= 1320 && width < 1500)
    else if (width >= 1600 && width < 1920)
      return 'lg';
    else
      return 'xl';
  }
}
